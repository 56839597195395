<template>
  <div class="order-develivery-method" :class="{ hiddenDeliveryMethod: $store.getters['getIsLoadingOrderItem'] }">
    <h2>{{ $t('checkout.delivery') }}</h2>
    <div
      v-for="deliveryMethod in $store.getters['getShipmentMethods']"
      :key="deliveryMethod.id"
      class="d-flex orderPaymentItem"
    >
      <div class="d-flex">
        <!--        <b-form-radio @input.native="changeDeliveryMethod" class="delivery-radio" v-model="selected"  name="some-radios" :value="deliveryMethod.id"/>-->
        <b-form-radio
          @input.native="changeDeliveryMethod(deliveryMethod.id)"
          v-model="selected"
          class="delivery-radio"
          name="some-radios"
          :value="deliveryMethod.id"
        />
        <div class="checkboxFlex">
          <div class="deliveryInfo">
            <p class="delivery-name" v-html="getDeliveryDescription(deliveryMethod.id)"></p>
            <!--            <p>{{deliveryMethod.description_nl}}</p>-->
          </div>
          <div class="deliveryPrice price">
            €{{
              (
                deliveryMethod.shippingprices.find((e) => e.maxWeight > $store.getters['getCalculator'].weightTotal)
                  .price / 100
              )
                .toFixed(2)
                .replace('.', ',')
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex return-order">
      <div class="d-flex return-order-text" @click="$store.dispatch('setCurrentPage', 'contact')">
        <i class="icon-chevron-left"></i>
        <span>{{ $t('checkout.return.to.contact') }}</span>
      </div>
      <div class="d-flex">
        <Button
          @click.native="parseInt(selected) > 0 ? $store.dispatch('setCurrentPage', 'payment') : (errorStatus = true)"
          style="margin-left: 13px"
          :button-text="$t('checkout.continue.to.payment')"
          button-type="primary"
        />
        <OrderError v-if="errorStatus" :error-text="$t('checkout.choose.option.to.continue')" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import OrderError from './OrderError'

export default {
  name: 'OrderDeliveryMethod',
  components: { Button, OrderError },
  data() {
    return {
      errorStatus: false,
      selected: this.$store.getters['getShipment'],
    }
  },
  methods: {
    async changeDeliveryMethod(deliveryMethodId) {
      const order = this.getOrder(deliveryMethodId)

      this.selected = deliveryMethodId

      await this.$store.dispatch('setShipment', deliveryMethodId)

      await this.$store.dispatch('getCheckout', order)
    },
    getOrder(deliveryMethodId) {
      return {
        id: this.$route.params.id,
        extraOrderIds: this.$store.getters['getOrderExtraIds'],
        printId: this.$store.getters['getPrint'],
        volume: this.$store.getters['getVolume'],
        paymentId: this.$store.getters['getPayment'],
        countryId: this.$store.getters['getPaymentUser'].countryId || 1,
        deliveryId: deliveryMethodId,
        proof: this.$store.getters['getProof'],
      }
    },
    getDeliveryDescription(shipmentId) {
      const language = localStorage.getItem('language')
      const shippingMethod = this.$store.getters['getShipmentMethods'].find((s) => s.id === shipmentId)

      if (shippingMethod === null || language === null) {
        return '-'
      }

      return (
        shippingMethod['name_' + language] +
        '<br /><b>' +
        shippingMethod.expectedDeliveryDate +
        '</b><br />' +
        shippingMethod['description_' + language]
      )
    },
  },
  async created() {
    console.log(this.$store.getters['getLoggedInUser'])

    const shipmentId = this.$store.getters['getShipment']
    const order = this.getOrder(shipmentId === '0' ? 0 : shipmentId)
    await this.$store.dispatch('getCheckout', order)

    if (shipmentId === '0') {
      this.selected = this.$store.getters['getShipmentMethods'][0].id
      await this.changeDeliveryMethod(this.selected)
    }

    await this.$store.dispatch('setCurrentPage', 'delivery')
  },
}
</script>

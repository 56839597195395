import { getCreationPages, getPreview } from '../creations/creationEndpoint'
// import userStore from '../user/user'

const state = {
  viewedAsCreation: true,
  viewedAsCreationInSettings: false,
  selectedOptions: false,
  newDocumentsCount: '0',
  inviteMenu: false,
  tempDocuments: [],
  documents: [],
  documentsFromApi: [],
  documentsToDelete: [],
  showModal: false,
  showInviteForm: false,
  showViewTeamModal: false,
  showPageNumbers: false,
  settingsChanged: false,
  allowDocumentSelect: false,
  usersView: [],
  documentRole: 0,
  editorPreview: false,
  isLindaMagazine: false,
  isGlossyMagazine: false,
  title: '',
  isShared: false,
  draggingNumber: 0,
  creationStatus: 0,
  magazine: null,
  updatedDocumentsLength: 0,
}

const getters = {
  getCreationStatus: (state) => {
    return state.creationStatus
  },
  getDocuments: (state) => {
    return state.documents
  },
  getPagesMagazine: (state) => {
    return state.magazine
  },
  getTempDocuments: (state) => {
    return state.tempDocuments
  },
  getViewedAsCreation: (state) => {
    return state.viewedAsCreation
  },
  getViewedAsCreationInSettings: (state) => {
    return state.viewedAsCreationInSettings
  },
  getSelectedOptions: (state) => {
    return state.selectedOptions
  },
  getNewDocumentsCount: (state) => {
    return state.newDocumentsCount
  },
  getInviteMenu: (state) => {
    return state.inviteMenu
  },
  getShowModal: (state) => {
    return state.showModal
  },
  getShowInviteForm: (state) => {
    return state.showInviteForm
  },
  getShowViewTeamModal: (state) => {
    return state.showViewTeamModal
  },
  getDocumentsToDelete: (state) => {
    return state.documentsToDelete
  },
  getShowPageNumbers: (state) => {
    return state.showPageNumbers
  },
  getSettingsChanged: (state) => {
    return state.settingsChanged
  },
  getDocumentsFromApi: (state) => {
    return state.documentsFromApi
  },
  getAllowDocumentSelect: (state) => {
    return state.allowDocumentSelect
  },
  getUsersView: (state) => {
    return state.usersView
  },
  getDocumentRole: (state) => {
    return state.documentRole
  },
  getEditorPreview: (state) => {
    return state.editorPreview
  },
  getTitle: (state) => {
    return state.title
  },
  getIsLindaMagazine: (state) => {
    return state.isLindaMagazine
  },
  getIsGlossyMagazine: (state) => {
    return state.isGlossyMagazine
  },
  getIsShared: (state) => {
    return state.isShared
  },
  getDraggingNumber: (state) => {
    return state.draggingNumber
  },
}

const actions = {
  async setDocuments({ commit }, magazineId) {
    try {
      const documents = await getCreationPages(magazineId)

      if (documents && documents.data && documents.data.status === 'Success') {
        const magazine = documents.data.data[0].magazine
        commit('SET_MAGAZINE', magazine)
        const pages = documents.data.data[0].magazine.magazinepages
        const isLinda = documents.data.data[0].magazine.canvasVersion === 'linda'
        const isGlossy = documents.data.data[0].magazine.canvasVersion === 'glossy'
        commit('SET_CREATION_STATUS', documents.data.data[0].magazine.status)
        commit('SET_DOCUMENTS', pages)
        commit('SET_DOCUMENTS_FROM_API', pages ? JSON.parse(JSON.stringify(pages)) : [])
        commit('SET_DOCUMENT_ROLE', documents.data.data[0].role.id)
        commit('SET_DOCUMENT_EDITOR_PREVIEW', documents.data.data[0].magazine.editorPreview !== 0)
        commit('SET_SHOW_PAGE_NUMBERS', documents.data.data[0].showPageNumbers !== 0)
        commit('SET_IS_LINDA_MAGAZINE', isLinda)
        commit('SET_IS_GLOSSY_MAGAZINE', isGlossy)
        commit('SET_IS_LINDA', isLinda)
        commit('SET_IS_GLOSSY', isGlossy)

        if (isLinda || isGlossy) {
          commit('SET_VIEWED_AS_CREATION_STATUS', false)
        } else {
          commit('SET_VIEWED_AS_CREATION_STATUS', true)
        }

        commit('SET_TITLE', documents.data.data[0].magazine.title)
        commit('SET_SHARED', documents.data.data[0].magazine.shared !== 0)
      }
    } catch (e) {
      console.error(e)
    }
  },
  async setDocumentsPreview({ commit }, magazineId) {
    try {
      const documents = await getPreview(magazineId)

      const pages = documents.data.data[0].magazine.magazinepages
      const isLinda = documents.data.data[0].magazine.canvasVersion === 'linda'
      const isGlossy = documents.data.data[0].magazine.canvasVersion === 'glossy'

      commit('SET_DOCUMENTS', pages)
      commit('SET_DOCUMENTS_FROM_API', JSON.parse(JSON.stringify(pages)))
      commit('SET_IS_LINDA', isLinda)
      commit('SET_IS_GLOSSY', isGlossy)
      commit('SET_SHARED', documents.data.data[0].magazine.shared !== 0)
      commit('SET_DOCUMENT_ROLE', documents.data.data[0].role.id)
    } catch (e) {
      console.error(e)
    }
  },
  async clearDocumentsFromApi({ commit }) {
    await commit('SET_DOCUMENTS_FROM_API', [])
  },
  setPageFromPusher({ commit }, payload) {
    const index = payload.pages.findIndex((o) => o.id === payload.canvas.id)
    payload.pages[index] = payload.canvas
    commit('SET_DOCUMENTS_FROM_API', payload.pages)
  },
  setNewDocumentsCount({ commit }, count) {
    commit('SET_NEW_DOCUMENTS_COUNT', count)
  },
  setViewedAsCreationStatus({ commit }, status) {
    commit('SET_VIEWED_AS_CREATION_STATUS', status)
  },
  setViewedAsCreationInSettingsStatus({ commit }, status) {
    commit('SET_VIEWED_AS_CREATION_IN_SETTINGS_STATUS', status)
  },
  setSelectedOptions({ commit }, status) {
    commit('SET_SELECTED_OPTIONS', status)
  },
  addDocumentsToCreation({ commit }, { count, lastPosition }) {
    if (count < 4) {
      count = 4
    }
    for (let i = 0; i < count; i++) {
      let document = {
        id: null,
        number: lastPosition + i + 1,
        document_image: '/page.png',
        newPage: true,
        type: 2,
      }
      commit('SET_NEW_CREATIONS_TO_DOCUMENT', document)
    }
  },
  removeNewPages({ commit }) {
    commit('REMOVE_NEW_PAGES')
  },
  openInviteMenu({ commit }, status) {
    commit('SET_INVITE_MENU', status)
  },
  setShowModal({ commit }, status) {
    commit('SET_SHOW_MODAL', status)
  },
  setShowInviteForm({ commit }, status) {
    commit('SET_SHOW_INVITE_FORM', status)
  },
  setShowViewTeamModal({ commit }, status) {
    commit('SET_SHOW_VIEW_TEAM_MODEL', status)
  },
  setDocumentsToDelete({ commit }, documents) {
    commit('SET_DOCUMENTS_TO_DELETE', documents)
  },
  setShowPageNumbers({ commit }, status) {
    commit('SET_SHOW_PAGE_NUMBERS', status)
  },
  setSettingsChanged({ commit }, status) {
    commit('SET_SETTINGS_CHANGED', status)
  },
  setAllowDocumentSelect({ commit }, status) {
    commit('SET_ALLOW_DOCUMENT_CLICK', status)
  },
  addUserView({ commit }, user) {
    commit('ADD_USER_VIEW', user)
  },
  removeUserView({ commit }, user) {
    commit('REMOVE_USER_VIEW', user)
  },
  clearUserView({ commit }) {
    commit('CLEAR_USER_VIEW')
  },
  setEditorPreview({ commit }, status) {
    commit('SET_DOCUMENT_EDITOR_PREVIEW', status)
  },
  setIsShared({ commit }, status) {
    commit('SET_SHARED', status)
  },
  updateDocumentPositions({ commit }) {
    commit('UPDATE_DOCUMENT_POSITIONS')
  },
  movePositionsUp({ commit }, startDocument) {
    commit('MOVE_POSITIONS_UP', startDocument)
  },
  movePositionsDown({ commit }, startDocument) {
    commit('MOVE_POSITIONS_DOWN', startDocument)
  },
  setDraggingNumber({ commit }, number) {
    commit('SET_DRAGGING_NUMBER', number)
  },
}

const mutations = {
  SET_MAGAZINE(state, val) {
    state.magazine = val
  },
  SET_DOCUMENT_STATUS(state, val) {
    const documents = state.documentsFromApi

    const document = documents.find((o) => o.number === val.n)

    document.status = val.s
  },
  SET_CREATION_STATUS(state, status) {
    state.creationStatus = status
  },
  SET_DOCUMENTS(state, documents) {
    state.documents = documents
  },
  SET_DOCUMENTS_FROM_API(state, documents) {
    state.documentsFromApi = [...documents]
  },
  SET_VIEWED_AS_CREATION_STATUS(state, status) {
    state.viewedAsCreation = status
  },
  SET_VIEWED_AS_CREATION_IN_SETTINGS_STATUS(state, status) {
    state.viewedAsCreationInSettings = status
  },
  SET_SELECTED_OPTIONS(state, status) {
    state.selectedOptions = status
    state.inviteMenu = false
  },
  SET_NEW_CREATIONS_TO_DOCUMENT(state, documents) {
    state.documents.push(documents)
  },
  REMOVE_NEW_PAGES(state) {
    removeByAttr(state.documents, 'newPage', true)
  },
  SET_NEW_DOCUMENTS_COUNT(state, count) {
    state.newDocumentsCount = count
  },
  SET_INVITE_MENU(state, status) {
    state.inviteMenu = status
  },
  SET_SHOW_MODAL(state, status) {
    state.showModal = status
    if (status === true) {
      document.body.classList.add('hiddenOverflow')
    } else {
      document.body.classList.remove('hiddenOverflow')
    }
  },
  SET_SHOW_INVITE_FORM(state, status) {
    state.showInviteForm = status
  },
  SET_SHOW_VIEW_TEAM_MODEL(state, status) {
    state.showViewTeamModal = status
  },
  SET_DOCUMENTS_TO_DELETE(state, documents) {
    state.documentsToDelete = documents
  },
  SET_SHOW_PAGE_NUMBERS(state, status) {
    state.showPageNumbers = status
  },
  SET_SETTINGS_CHANGED(state, status) {
    state.settingsChanged = status
  },
  SET_ALLOW_DOCUMENT_CLICK(state, status) {
    state.allowDocumentSelect = status
  },
  ADD_USER_VIEW(state, user) {
    state.usersView.push(user)
  },
  REMOVE_USER_VIEW(state, user) {
    let index = state.usersView.indexOf(user)
    if (index >= 0) {
      state.usersView.splice(index, 1)
    }
  },
  CLEAR_USER_VIEW(state) {
    state.usersView = []
  },
  SET_DOCUMENT_ROLE(state, roleId) {
    state.documentRole = roleId
  },
  SET_DOCUMENT_EDITOR_PREVIEW(state, editorPreview) {
    state.editorPreview = editorPreview
  },
  SET_TITLE(state, title) {
    state.title = title
  },
  SET_IS_LINDA_MAGAZINE(state, isLindaMagazine) {
    state.isLindaMagazine = isLindaMagazine
  },
  SET_IS_GLOSSY_MAGAZINE(state, isGlossyMagazine) {
    state.isGlossyMagazine = isGlossyMagazine
  },
  SET_SHARED(state, status) {
    state.isShared = status
  },
  UPDATE_DOCUMENT_POSITIONS(state) {
    let documents = state.documents
    let list = []
    documents.filter((document) => {
      if (document.type === 2 || document.type === 3) {
        list.push(document)
      }
    })
    let sortedList = list.sort((a, b) => {
      return a.number - b.number
    })

    sortedList.forEach((document, index) => {
      document.number = index + 2
    })

    let backCover = documents.find((document) => document.type === 4)

    backCover.number = documents.length
  },
  MOVE_POSITIONS_UP(state, startDocument) {
    let documents = state.documents
    let list = []
    documents.filter((document) => {
      if (
        (document.type === 2 || document.type === 3) &&
        document.number >= startDocument.number &&
        document !== startDocument
      ) {
        list.push(document)
      }
    })
    let sortedList = list.sort((a, b) => {
      return a.number - b.number
    })

    let index = startDocument.number + 1

    sortedList.forEach((document) => {
      document.number = index
      index++
    })

    let backCover = documents.find((document) => document.type === 4)

    backCover.number = documents.length
  },
  MOVE_POSITIONS_DOWN(state, startDocument) {
    let documents = state.documents
    let list = []
    documents.filter((document) => {
      if (
        (document.type === 2 || document.type === 3) &&
        document.number <= startDocument.number &&
        document !== startDocument
      ) {
        list.push(document)
      }
    })
    let sortedList = list.sort((a, b) => {
      return b.number - a.number
    })

    let index = startDocument.number - 1

    sortedList.forEach((document) => {
      document.number = index
      index--
    })

    let backCover = documents.find((document) => document.type === 4)

    backCover.number = documents.length
  },
  SET_DRAGGING_NUMBER(state, number) {
    state.draggingNumber = number
  },
  SET_UPDATED_DOCUMENTS_LENGTH(state, length) {
    state.updatedDocumentsLength = length
  },
}

let removeByAttr = function (arr, attr, value) {
  let i = arr.length
  while (i--) {
    if (
      arr[i] &&
      // eslint-disable-next-line no-prototype-builtins
      arr[i].hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1)
    }
  }
  return arr
}

export default {
  state,
  getters,
  actions,
  mutations,
}

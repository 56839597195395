<template>
  <div class="order-content">
    <OrderCannotModal v-if="showOrderCannotModal" @closeModal="showOrderCannotModal = false" />

    <div
      v-if="
        ($store.getters['getOrderContentType'] === 'standard' || $store.getters['getOrderContentType'] === 'both') &&
        !$store.getters['getCanOrderExtrasOnly']
      "
    >
      <h2>{{ $t('checkout.order') }}</h2>
      <OrderCheckoutSelect
        id="1"
        v-model="selectedProofOptions"
        :label-text="$t('checkout.creation')"
        :options="options"
        icon="chevron-down"
        icon-color="#534C45"
        @valueChanged="setSelectedProof($event)"
      />
      <Input
        v-if="$store.getters['getIsLinda']"
        id="Print_option"
        :isDisabled="true"
        :label-text="$t('checkout.print')"
        class="disabled-input-order"
        icon="locker-filled"
        placeholder-text="Print option"
        style="width: 402px"
        v-model="lindaPrintOption"
      />
      <Input
        v-if="$store.getters['getIsGlossy']"
        id="Print_option"
        :isDisabled="true"
        :label-text="$t('checkout.print')"
        class="disabled-input-order"
        icon="locker-filled"
        placeholder-text="Print option"
        style="width: 402px"
        v-model="glossyPrintOption"
      />
      <OrderCheckoutSelect
        v-else
        id="2"
        v-model="selectedPrintOption"
        :label-text="$t('checkout.print')"
        :options="printOptions"
        class="printSelect"
        icon="chevron-down"
        icon-color="#534C45"
        @valueChanged="setSelectedPrint($event)"
      />
      <Input
        id="volume_option"
        v-model="amount"
        :class="{ errorBorder: this.amountInvalid || this.$store.getters['getIsInvalidVolume'] }"
        :label-text="$t('checkout.volume')"
        :placeholder-text="$t('checkout.volume.placeholder')"
        style="width: 155px; margin-bottom: 0"
        @change.native="changeVolume"
      />
      <Error
        v-if="this.amountInvalid || this.$store.getters['getIsInvalidVolume']"
        :errorMessage="$t('checkout.error.choose.number')"
        icon="warning"
        icon-color="#C23934"
      />
    </div>
    <div
      v-else-if="$store.getters['getOrderContentType'] === 'both' && $store.getters['getCanOrderExtrasOnly']"
      class="physical-order-info"
    >
      <i class="icon-info-fill"></i>
      <p>{{ $t('checkout.physicalOrder.instruction') }}</p>
      <Button
        :buttonText="$t('checkout.physicalOrder.instruction.button')"
        buttonType="primary"
        @click.native="() => (showOrderCannotModal = true)"
      />
    </div>

    <div
      v-if="
        $store.getters['getOrderContentType'] === 'both' ||
        $store.getters['getOrderContentType'] === 'poster' ||
        $store.getters['getCanOrderExtrasOnly']
      "
    >
      <h2 style="margin-top: 32px !important">PDF</h2>
      <p class="extraHeader">{{ $t('checkout.order.pdf') }}:</p>
      <div class="d-flex">
        <OrderItemTile
          v-for="option in extras.filter((o) => o.name.includes('pdf'))"
          :key="option.id"
          :class="{
            'order-item-tile-selected': selectedPosterOptionsPDF.includes(option.id),
          }"
          :price="(option.price / 100).toFixed(2).toString().replace('.', ',')"
          :text="option.desc"
          @click.native="selectOptionPdf(option.id)"
        />
      </div>
      <h2>Poster</h2>
      <p class="extraHeader">{{ $t('checkout.order.cover') }}:</p>
      <div class="d-flex" style="width: 348px">
        <b-row>
          <b-col v-for="option in extras.filter((o) => o.name.includes('poster'))" :key="option.id" lg="6">
            <OrderItemTile
              :class="{
                'order-item-tile-selected': selectedPosterOptionsForOrder.includes(option.id),
              }"
              :price="(option.price / 100).toFixed(2).toString().replace('.', ',')"
              :text="option.desc"
              @click.native="selectOption(option.id)"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="d-flex" style="margin-top: 32px">
      <Button
        :button-text="$t('checkout.button.continue')"
        button-type="primary"
        @click.native="submit"
        v-if="
          (this.amount > 0 ||
            this.selectedPosterOptionsPDF.length > 0 ||
            this.selectedPosterOptionsForOrder.length > 0) &&
          !errorStatus
        "
      />
      <Button :button-text="$t('checkout.button.continue')" button-type="disabled" disabled v-else />
      <OrderError
        v-if="errorStatus && !$store.getters['getCanOrderExtrasOnly']"
        :error-text="getErrorMessage()"
        icon="alert-filled"
        icon-color="#C23934"
      />
    </div>
  </div>
</template>

<script>
import OrderItemTile from '@/domains/order/OrderItemTile'
import Button from '@/components/Button'
import OrderCheckoutSelect from '@/domains/order/OrderCheckoutSelect'
import Input from '@/components/Input'
import Error from '@/components/Error'
import { numeric } from 'vuelidate/lib/validators'
import OrderError from '@/domains/order/OrderError'
import OrderCannotModal from './OrderCannotModal.vue'

export default {
  name: 'OrderContent',
  components: {
    OrderError,
    Error,
    Input,
    OrderCheckoutSelect,
    Button,
    OrderItemTile,
    OrderCannotModal,
  },
  validations: {
    amount: {
      numeric,
      minValue: 0,
    },
  },
  data() {
    return {
      amount: (this.amount = this.$store.getters['getVolume'] || ''),
      amountInvalid: false,
      errorStatus: false,
      selectedPrintOption: Number(this.$store.getters['getPrint']),
      lindaPrintOption: '',
      glossyPrintOption: '',
      selectedProofOptions: this.$store.getters['getProof'],
      selectedPosterOptionsForOrder: this.$store.getters['getSelectedPosters'],
      selectedPosterOptionsPDF: this.$store.getters['getSelectedPDFS'],
      printOptions: [],
      options: [
        { value: 'false', name: this.$t('checkout.final.creation') },
        { value: 'true', name: this.$t('checkout.sample.copy') },
      ],
      extras: [],
      showOrderCannotModal: false,
    }
  },
  methods: {
    getErrorMessage() {
      if (
        !this.amountInvalid &&
        (this.selectedPosterOptionsForOrder.length === 0 || this.selectedPosterOptionsPDF.length === 0)
      ) {
        return this.$t('checkout.error.fields')
      }
      return this.$t('checkout.error.volume')
    },
    setSelectedProof($event) {
      this.selectedProofOptions = $event.value
      this.changeVolume()
    },
    setSelectedPrint($event) {
      this.selectedPrintOption = $event.id
      this.$store.dispatch('setPrint', this.selectedPrintOption)
      this.changeVolume()
    },
    changeVolume() {
      if (this.$store.getters['getCanOrderPFDOnly'] && this.$store.getters['getCanOrderExtrasOnly']) {
        return
      }

      if (this.$v.amount.$invalid) {
        this.$store.dispatch('setIsInvalidVolume', true)
        this.errorStatus = true
        return
      } else if (
        this.selectedPosterOptionsPDF.length === 0 &&
        this.selectedPosterOptionsForOrder.length === 0 &&
        Number(this.amount) === 0
      ) {
        this.errorStatus = true
        this.amountInvalid = true
        this.$store.dispatch('setIsEmptyOrder', true)
        return
      }

      const extraOrderIds = this.selectedPosterOptionsForOrder.concat(this.selectedPosterOptionsPDF).toString()

      let order = {
        id: this.$route.params.id,
        extraOrderIds: extraOrderIds,
        printId: Number(this.amount) === 0 ? 0 : this.selectedPrintOption,
        proof: this.selectedProofOptions,
        volume: this.amount || 0,
        paymentId: this.$store.getters['getPayment'],
        deliverId: this.$store.getters['getShipment'],
        countryId: this.$store.getters['getPaymentUser'].countryId || 1,
      }

      // if (this.$store.getters['getCanOrderExtrasOnly'] ) {
      //   this.$store.dispatch('setOrderExtraIds', extraOrderIds);
      //   // const order = {
      //   //   id: this.$route.params.id,
      //   //   extraOrderIds: extraOrderIds,
      //   //   paymentId: 0,
      //   //   printId: 0,
      //   //   volume: 0,
      //   //   countryId: 1,
      //   //   proof: false,
      //   //   deliveryId: 0,
      //   // }
      //   //this.$store.dispatch('getCheckoutPDFOnly', order)
      //   this.$store.dispatch('setVolume', parseInt(this.amount));
      //   this.$store.dispatch('setOrderExtraIds', extraOrderIds);
      //   this.$store.dispatch('setPrint', this.selectedPrintOption);
      //   this.$store.dispatch('setProof', this.selectedProofOptions);
      //   this.$store.dispatch("getCheckout", order);
      //   return;
      // }

      this.$store.dispatch('setVolume', parseInt(this.amount || 0))
      this.$store.dispatch('setOrderExtraIds', extraOrderIds)
      this.$store.dispatch('setPrint', this.selectedPrintOption)
      this.$store.dispatch('setProof', this.selectedProofOptions)
      this.$store.dispatch('getCheckout', order)

      this.errorStatus = false
      this.amountInvalid = false
      this.$store.dispatch('setIsEmptyOrder', false)
      this.$store.dispatch('setIsInvalidVolume', false)
      this.amount = this.$store.getters['getVolume']
    },
    selectOption(option) {
      if (this.selectedPosterOptionsForOrder.includes(option)) {
        let index = this.selectedPosterOptionsForOrder.indexOf(option)
        if (index > -1) {
          this.selectedPosterOptionsForOrder.splice(index, 1)
        }
      } else {
        this.selectedPosterOptionsForOrder.push(option)
        this.errorStatus = false
      }
      this.changeVolume()
    },
    selectOptionPdf(option) {
      if (this.selectedPosterOptionsPDF.includes(option)) {
        let index = this.selectedPosterOptionsPDF.indexOf(option)
        if (index > -1) {
          this.selectedPosterOptionsPDF.splice(index, 1)
        }
      } else {
        this.selectedPosterOptionsPDF.push(option)
        this.errorStatus = false
      }
      this.changeVolume()
    },
    submit() {
      if (this.$store.getters['getCanOrderExtrasOnly']) {
        this.$store.dispatch('setCurrentPage', 'contact')
        return
      }

      if (this.errorStatus) {
        return
      }

      this.$store.dispatch('setCurrentPage', 'contact')
    },
    setPrintOptions() {
      const isLinda = this.$store.getters['getIsLinda']
      const isGlossy = this.$store.getters['getIsGlossy']

      const printOptions = this.$store.getters['getPrintOptions']

      if (isLinda) {
        const lindaOption = printOptions.find((o) => o.id === 5)
        this.lindaPrintOption = 'LINDA.'

        this.printOptions.push(lindaOption)
        this.selectedPrintOption = lindaOption.id
      } else if (isGlossy) {
        this.glossyPrintOption = 'Glossy'
        this.selectedPrintOption = 2
      } else {
        for (let i = 0; i < printOptions.length; i++) {
          const option = printOptions[i]

          //Skip linda option
          if (option.id === 5) break

          switch (option.name) {
            case 'stapled':
              option.name = this.$t('checkout.stapled')
              break
            case 'glossy':
              option.name = this.$t('checkout.glossy')
              break
            case 'glossycover':
              option.name = this.$t('checkout.glossycover')
              break
            case 'book':
              option.name = this.$t('checkout.book')
              break
          }
          this.printOptions.push(option)
        }

        this.selectedPrintOption = this.printOptions[0].id
      }
    },
    setExtras() {
      this.extras = this.$store.getters['getOrderExtras']
      for (let i = 0; i < this.extras.length; i++) {
        if (this.extras[i].name === 'pdf_72dpi_magazine') {
          this.extras[i].desc = this.$t('checkout.low.quality')
        } else if (this.extras[i].name === 'pdf_300dpi_cover') {
          this.extras[i].desc = this.$t('checkout.high.quality')
        } else if (this.extras[i].name === 'poster_a0') {
          this.extras[i].desc = 'A0 (1189x841mm)'
        } else if (this.extras[i].name === 'poster_a1') {
          this.extras[i].desc = 'A1 (841x594mm)'
        } else if (this.extras[i].name === 'poster_a2') {
          this.extras[i].desc = 'A2 (594x420mm)'
        } else if (this.extras[i].name === 'poster_a3') {
          this.extras[i].desc = 'A3 (420x210mm)'
        } else if (this.extras[i].name === 'poster_a3_plus') {
          this.extras[i].desc = 'A3++ (500x350mm)'
        } else if (this.extras[i].name === 'poster_a4') {
          this.extras[i].desc = 'A4 (297x210mm)'
        }
      }
    },
  },
  async created() {
    await this.setPrintOptions()
    await this.setExtras()
    await this.changeVolume()
  },
}
</script>

import { render, staticRenderFns } from "./Creation.vue?vue&type=template&id=db93609e&scoped=true"
import script from "./Creation.vue?vue&type=script&lang=js"
export * from "./Creation.vue?vue&type=script&lang=js"
import style0 from "./Creation.vue?vue&type=style&index=0&id=db93609e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db93609e",
  null
  
)

export default component.exports
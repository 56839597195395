<template>
  <div class="document" :class="{ documentToDelete: checkIfSetUpForDelete[0] }">
    <div class="relative">
      <div class="documentGradient" v-if="img !== '/page.png'"></div>
      <DownloadDocument :magazine-id="magazineId" :magazinepage-id="magazinePageId" v-if="img !== '/page.png'" />
      <img
        :src="img"
        alt="img"
        class="coverImg page-wrap dragImg"
        :class="{
          ['img-' + magazinePageId]: true,
          imgOpacity: $store.getters['getShowModal'] && (pageCover === 1 || pageCover === 4),
        }"
        v-if="img !== '/page.png' && !newPage"
      />
      <img
        src="/page.png"
        :class="{ ['img-' + magazinePageId]: true }"
        alt="img"
        class="emptyPage dragImg"
        v-if="img === '/page.png' && !newPage"
      />
      <img src="../assets/emptyplus.png" alt="img" class="emptyPage dragImg" v-if="newPage" />
      <i class="icon-delete" v-if="checkIfSetUpForDelete[0]"></i>
      <Button @click.native="startEdit" button-text="Start" button-type="primary" class="editButton" />

      <!-- When this document is getting generated after "use template" action -->
      <div
        v-if="type === 'initializing' || type === 'pending' || showProgress"
        class="bg-white absolute top-0 left-0 w-full h-full flex justify-center items-center"
        style="padding: 0 16px"
      >
        <div v-show="!showOk" class="w-full bg-wg-80 bg-opacity-30 h-3 rounded-2xl relative overflow-hidden">
          <span class="absolute top-0 left-0 h-3 bg-black" :style="`width: ${progress}%`"></span>
        </div>
        <span
          v-show="showOk"
          class="rounded-full flex justify-center items-center bg-wg-light-accent"
          style="width: 22px; height: 22px"
        >
          <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9391 1.47559C11.8888 1.61766 11.8086 1.74888 11.7033 1.86153L5.7051 8.59627C5.59839 8.71604 5.46619 8.81369 5.31691 8.88301C5.16764 8.95232 5.00456 8.99177 4.83809 8.99884C4.67162 9.00592 4.50539 8.98046 4.35002 8.9241C4.19464 8.86774 4.05352 8.7817 3.93564 8.67148L0.336751 5.30411C0.118229 5.09241 -0.00268791 4.80887 4.5349e-05 4.51457C0.00277861 4.22026 0.128943 3.93874 0.351364 3.73062C0.573786 3.52251 0.874668 3.40446 1.18921 3.40191C1.50375 3.39935 1.80678 3.51249 2.03303 3.71695L4.70221 6.21554L9.86062 0.42478C9.96005 0.307533 10.0835 0.210076 10.2238 0.138142C10.3641 0.0662071 10.5183 0.0212483 10.6774 0.00590995C10.8365 -0.00942841 10.9973 0.00516291 11.1503 0.0488268C11.3033 0.0924907 11.4455 0.164346 11.5684 0.260162C11.6912 0.355979 11.7924 0.473823 11.8659 0.606762C11.9394 0.739701 11.9837 0.88505 11.9963 1.03426C12.0089 1.18347 11.9894 1.33352 11.9391 1.47559Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
    </div>

    <div class="pagenumber mt-2">
      <p
        class="ml-2 pageNumber"
        :class="{
          opacityColor: $store.getters['getShowModal'] && (pageCover === 1 || pageCover === 4),
        }"
      >
        {{ getPageAlias }}
      </p>
      <div class="invitedUsers" v-if="checkIfUserInPage.length > 0">
        <div :title="getUserPages(u)" v-for="(u, index) in checkIfUserInPage" :key="u.id + index" class="invtited-user">
          <v-gravatar :email="u.email" :key="'pageUser' + u.nickName" />
        </div>
      </div>
      <div class="text-sm-center" v-if="type === 'lock'">
        <Lock class="w-25 h-25" />
      </div>
      <div class="text-sm-center" v-if="type === 'ok'">
        <Ok class="w-25 h-25" />
      </div>
    </div>
  </div>
</template>

<script>
import Lock from '@/components/Lock'
import Ok from '@/components/Ok'
import Button from '@/components/Button'
import DownloadDocument from './DownloadDocument'

export default {
  props: [
    'page',
    'IdsToDelete',
    'documentId',
    'newPage',
    'invitee',
    'type',
    'pageCover',
    'magazineId',
    'magazinePageId',
    'imageUrl',
    'isLinda',
    'isGlossy',
    'backCover',
    'frontCover',
  ],
  name: 'Document',
  data() {
    return {
      editorUrl: process.env.VUE_APP_EDITOR_URL,
      app_status: process.env.VUE_APP_STATUS,
      img: this.isLinda ? '/linda_blank.png' : '/blank.png',
      isImageAvailable: '',
      progress: 0,
      showProgress: false,
      showOk: false,
    }
  },
  created() {
    this.imageUrl.then((response) => {
      this.isImageAvailable = response
    })
  },
  watch: {
    imageUrl: {
      immediate: true,
      handler() {
        this.imageUrl.then((response) => {
          this.isImageAvailable = response
        })
      },
    },

    isImageAvailable(val) {
      if (typeof val === 'string') {
        this.img = val
      }
    },

    type: {
      immediate: true,
      handler() {
        if (this.type === 'pending') {
          this.progress = 0
          this.showProgress = true
        } else if (this.type === 'initializing') {
          this.progress = 50
        } else {
          this.progress = 100

          setTimeout(() => {
            this.showOk = true
          }, 250)

          setTimeout(() => {
            this.showProgress = false
            this.$store.commit('SET_UPDATED_DOCUMENTS_LENGTH', this.updatedDocumentsLength + 1)
          }, 750)

          this.imageUrl.then((response) => {
            this.isImageAvailable = response
          })
        }
      },
    },
  },
  methods: {
    getClassName() {
      return 'img-' + this.magazinePageId
    },
    setDefaultImg() {
      this.img = '/page.png'
    },
    startEdit() {
      const bearer = localStorage.getItem('jilster_token')

      if ((this.isLinda && this.pageCover === 1) || (this.isGlossy && this.pageCover === 1)) {
        window.open(
          `${this.editorUrl}/${this.magazineId}/${this.magazinePageId}/${this.backCover}/right#${bearer}`,
          '_blank'
        )
        return
      }

      if ((this.isLinda && this.pageCover === 4) || (this.isGlossy && this.pageCover === 4)) {
        window.open(
          `${this.editorUrl}/${this.magazineId}/${this.magazinePageId}/${this.frontCover}/left#${bearer}`,
          '_blank'
        )
        return
      }

      window.open(`${this.editorUrl}/${this.magazineId}/${this.magazinePageId}#${bearer}`, '_blank')
    },
    getUserPages(user) {
      let pages = []
      for (let i = 0; i < user.pages.length; i++) {
        pages.push(user.pages[i].number)
      }
      return `${user.name} \n Pages: ${pages}`
    },
  },
  computed: {
    updatedDocumentsLength() {
      return this.$store.state.documents.updatedDocumentsLength
    },
    getPageAlias() {
      if (this.pageCover === 1) {
        return this.$t('editor.miscellaneous.cover')
      } else if (this.pageCover === 4) {
        return this.$t('editor.miscellaneous.backcover')
      }
      return this.page
    },
    userInfo() {
      let pages = []
      for (let i = 0; i < this.invitee[0].pages.length; i++) {
        pages.push(this.invitee[0].pages[i].number)
      }
      return `${this.invitee[0].name} \n Pages: ${pages}`
    },
    checkIfSetUpForDelete() {
      if (this.IdsToDelete !== undefined && this.IdsToDelete.length > 0) {
        let ids = this.IdsToDelete
        return ids.filter((id) => {
          if (id === this.documentId) {
            return true
          }
        })
      }
      return false
    },
    checkIfUserInPage() {
      let users = this.$store.getters['getUsersView'].filter((u) => u.pages.find((p) => p.id == this.documentId))
      if (users !== null && users !== undefined && users.length > 0) {
        return users
      }
      return []
    },
  },
  components: { DownloadDocument, Lock, Ok, Button },
}
</script>
<style>
.page-wrap img {
  background-color: white !important;
}
</style>

<template>
  <Maintenance v-if="maintenanceModeCheck" />
  <IsMobile v-else-if="detectMobilePhone" />
  <b-container v-else id="app" fluid @loggedIn="setPusherForDuplicationNotifications">
    <Navbar
      v-if="isNotAuth"
      @on-click="
        $store.commit('SET_SHOW_SCRATCH_MODAL_LINDA', true)
        $store.commit('SET_SHOW_SCRATCH_MODAL', true)
      "
    />
    <AuthNavbar v-else />
    <Sidebar
      v-if="!this.$store.getters['getLoadingDocument'] && sidebarVisibilityCheck"
      @show-take-a-look-modal="showBannerModal = true"
    />

    <router-view />

    <DoneEditing v-if="$store.getters['getDuplicatedCreation']" :duplication="true" />

    <AwardInfoModal v-if="showAwardGuideModal && showAwardUI" />

    <!-- Modal Start Creation -->
    <ModalContainer
      v-if="showScratchModal"
      :title="startCreationModal.title"
      :description="startCreationModal.description"
      :is-short="true"
      @on-close="$store.commit('SET_SHOW_SCRATCH_MODAL', false)"
    >
      <div class="grid grid-cols-2 gap-4 lg:gap-8">
        <Card
          :data="startCreationMagazine"
          show-info-btn
          @on-click="startMagazine"
          @on-info-click="
            () => {
              showInfoModal = true
              infoModalContent.title = startCreationMagazine.infoModalTitle
              infoModalContent.description = startCreationMagazine.infoModalDescription
            }
          "
        />
        <Card
          :data="startCreationBook"
          show-info-btn
          @on-click="startBook"
          @on-info-click="
            () => {
              showInfoModal = true
              infoModalContent.title = startCreationBook.infoModalTitle
              infoModalContent.description = startCreationBook.infoModalDescription
            }
          "
        />
        <Card
          :data="startCreationGlossy"
          show-info-btn
          @on-click="startGlossy"
          @on-info-click="
            () => {
              showInfoModal = true
              infoModalContent.title = startCreationGlossy.infoModalTitle
              infoModalContent.description = startCreationGlossy.infoModalDescription
            }
          "
        />
        <Card v-if="checkLindaSetting" :data="startCreationLinda" @on-click="startLinda" />
        <Card v-if="showScratchModalLinda" :data="startCreationTemplates" @on-click="startTemplate" />
      </div>
      <!--
      <div class="rounded-2xl my-14" style="background-color: #f9f8f8">
        <div class="p-8" :class="showBindingOptions ? 'pb-0' : ''">
          <h2 class="text-2xl text-center font-bold mb-2 text-wg-100">
            {{ $t('start.creation.modal.binding.options.title') }}
          </h2>
          <p class="text-wg-80 text-center mx-auto" style="max-width: 900px">
            <span>{{ $t('start.creation.modal.binding.options.description.before.click.here.button') }}</span>
            <br />
            <span v-if="!showBindingOptions"
              ><button
                class="text-wg-light-accent border-b border-solid border-wg-light-accent"
                style="padding-bottom: 0.25px"
                @click="showBindingOptions = true"
              >
                {{ $t('start.creation.modal.binding.options.description.click.here.button') }}
              </button>
              {{ $t('start.creation.modal.binding.options.description.after.click.here.button') }}</span
            >
            <span v-else>
              <button
                class="text-wg-light-accent border-b border-solid border-wg-light-accent"
                style="padding-bottom: 0.25px"
                @click="showBindingOptions = false"
              >
                {{ $t('start.creation.modal.binding.options.description.close.button') }}
              </button>
            </span>
          </p>
        </div>

        <div
          v-if="showBindingOptions"
          class="py-8 px-12 rounded-lg"
          style="box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2)"
        >
          <BindingOptions />
        </div>
      </div>
       -->
    </ModalContainer>

    <!-- Info modal -->
    <div v-if="showInfoModal">
      <div
        class="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
        @click.self="
          () => {
            showInfoModal = false
            infoModalContent.title = ''
            infoModalContent.description = ''
          }
        "
      >
        <div class="bg-white p-8 rounded-2xl relative" style="max-width: 683px">
          <h3 class="text-2xl font-bold text-wg-light-accent">{{ infoModalContent.title }}</h3>
          <div class="text-wg-80 text-lg md-body mt-6" v-html="infoModalContent.description"></div>

          <button
            @click="
              () => {
                showInfoModal = false
                infoModalContent.title = ''
                infoModalContent.description = ''
              }
            "
            class="absolute top-0 right-0"
            style="margin-top: 0.75rem; margin-right: 0.75rem"
          >
            <span class="jilster-font" style="font-size: 24px; color: #999087">k</span>
          </button>
        </div>
      </div>
    </div>

    <!-- "Take a look" Banner Modal -->
    <TakeALookBanner v-if="showBannerModal" @on-close="showBannerModal = false"> </TakeALookBanner>

    <!-- Single Template Modal -->
    <TemplateModal
      v-if="showTemplateModal"
      :selected-template="selectedTemplate"
      @on-close="$store.commit('SET_SHOW_TEMPLATE_MODAL', false)"
    />
  </b-container>
</template>
<script>
import Navbar from '@/components/Navbar'
import Sidebar from '@/components/Sidebar'
import AuthNavbar from '@/components/AuthNavbar'
import AwardInfoModal from '@/components/magazine-award/AwardInfoModal'

import axios from 'axios'
import DoneEditing from './components/DoneEditing'
import Pusher from 'pusher-js'
import Vue from 'vue'
import { getSettings } from './domains/system/systemEndpoint'
import systemSettingsSetup from './mixins/systemSettingsSetup'

import Maintenance from './domains/system/Maintenance'
import detectMobilePhone from './mixins/detectMobilePhone'
import IsMobile from './domains/mobile/IsMobile'

import ModalContainer from './domains/home/ModalContainer.vue'
import Card from './domains/home/Card.vue'
// import BindingOptions from './domains/home/BindingOptions.vue'
import TakeALookBanner from './components/TakeALookBanner.vue'
import TemplateModal from './domains/home/TemplateModal.vue'

import cardImageMagazine from '@/assets/start_magazine.png'
import cardImageBook from '@/assets/start_book.png'
import cardImageGlossy from '@/assets/start_glossy.png'
import cardImageLinda from '@/assets/start_linda.png'
import cardImageTemplate from '@/assets/start_template.png'

import httpClient from '../httpClient'

export default {
  components: {
    IsMobile,
    Maintenance,
    DoneEditing,
    AuthNavbar,
    Navbar,
    Sidebar,
    ModalContainer,
    Card,
    // BindingOptions,
    TakeALookBanner,
    TemplateModal,
    AwardInfoModal,
  },
  mixins: [systemSettingsSetup, detectMobilePhone],
  data() {
    return {
      showInfoModal: false,
      infoModalContent: {
        title: '',
        description: '',
      },
      isWatching: true,
      showBindingOptions: false,
      showBannerModal: false,
      startCreationMagazine: {
        title: this.$t('start.magazine.modal.title'),
        description: this.$t('start.magazine.modal.description'),
        buttonText: this.$t('start.magazine.modal.button'),
        image: cardImageMagazine,
        size: '21 x 29,7 cm',
        infoModalTitle: this.$t('start.magazine.modal.info.title'),
        infoModalDescription: this.$t('start.magazine.modal.info.description'),
      },
      startCreationBook: {
        title: this.$t('start.book.modal.title'),
        description: this.$t('start.book.modal.description'),
        buttonText: this.$t('start.book.modal.button'),
        image: cardImageBook,
        size: '21 x 29,7 cm',
        infoModalTitle: this.$t('start.book.modal.info.title'),
        infoModalDescription: this.$t('start.book.modal.info.description'),
      },
      startCreationGlossy: {
        title: this.$t('start.glossy.modal.title'),
        description: this.$t('start.glossy.modal.description'),
        buttonText: this.$t('start.glossy.modal.button'),
        infoModalTitle: this.$t('start.glossy.modal.info.title'),
        infoModalDescription: this.$t('start.glossy.modal.info.description'),
        image: cardImageGlossy,
        size: '21 x 29,7 cm',
      },
      startCreationLinda: {
        title: this.$t('start.linda.modal.title'),
        description: this.$t('start.linda.modal.description'),
        buttonText: this.$t('start.linda.modal.button'),
        image: cardImageLinda,
        isLinda: true,
        size: '23 x 27,1 cm',
      },
      startCreationTemplates: {
        title: this.$t('start.template.modal.title'),
        description: this.$t('start.template.modal.description'),
        buttonText: this.$t('start.template.modal.button'),
        image: cardImageTemplate,
      },
      startCreationModal: {
        title: this.$t('start.creation.modal.title'),
        description: this.$t('start.creation.modal.description'),
      },
    }
  },
  computed: {
    showAwardGuideModal() {
      return this.$store.state.magazineAward.showAwardGuideModal
    },
    showScratchModalLinda() {
      return this.$store.state.home.showScratchModalLinda
    },
    showScratchModal() {
      return this.$store.state.home.showScratchModal
    },
    isNotAuth() {
      return (
        this.$route.name !== 'Invitation' &&
        this.$route.name !== 'ForgotPassword' &&
        this.$route.name !== 'UpdatePassword' &&
        this.$route.name !== 'Login' &&
        this.$route.name !== 'Register' &&
        this.$route.name !== 'StartCreation' &&
        this.$route.name !== 'Checkout' &&
        this.$route.name !== 'Start' &&
        this.$route.name !== 'notfound'
      )
    },
    sidebarVisibilityCheck() {
      return (
        this.$route.name !== 'UpdatePassword' &&
        this.$route.name !== 'Invitation' &&
        this.$route.name !== 'ForgotPassword' &&
        this.$route.name !== 'Login' &&
        this.$route.name !== 'Register' &&
        this.$route.name !== 'StartCreation' &&
        this.$route.name !== 'Home' &&
        this.$route.name !== 'Checkout' &&
        this.$route.name !== 'Start' &&
        this.$route.name !== 'DocumentOverview' &&
        this.$route.name !== 'NewDesign' &&
        this.$route.name !== 'OrderCheckout' &&
        this.$route.name !== 'Preview' &&
        this.$route.name !== 'notfound' &&
        this.$route.name !== 'PreparingTemplate'
      )
    },
    navbarVisibilityCheck() {
      return this.$route.name !== 'PreparingTemplate'
    },
    maintenanceModeCheck() {
      const settings = this.$store.getters['getSettings']
      if (settings.length !== 0) {
        const maintenanceMode = settings.find((item) => item.name === 'SYSTEM_IN_MAINTENANCE')
        return maintenanceMode?.active ?? false
      }
      return false
    },
    getLoggedInUser() {
      return this.$store.getters['getLoggedInUser']
    },

    showAwardUI() {
      return this.$store.state.magazineAward.showAwardUI
    },

    activeCampaign() {
      return this.$store.state.magazineAward.activeCampaign
    },

    showTemplateModal() {
      return this.$store.state.home.showTemplateModal
    },

    selectedTemplate() {
      return this.$store.state.home.selectedTemplate
    },

    checkLindaSetting() {
      const settings = this.$store.getters['getSettings']

      const linda = settings.find((item) => item.name === 'LINDA_CARRIER')
      return (linda && linda.active) || false
    },
  },

  watch: {
    getLoggedInUser: {
      async handler(val) {
        if (val && this.isWatching) {
          await this.$store.dispatch('setActiveCampaign')
          this.isWatching = false
        }
      },
      immediate: true,
    },

    isNotAuth: {
      async handler(val) {
        const startFromTemplate = this.$store.state.general.startFromTemplate
        const templateId = this.$store.state.general.templateId

        if (val && startFromTemplate && templateId) {
          this.$router.push({ name: 'PreparingTemplate', params: { id: templateId } })

          this.$store.commit('SET_START_FROM_TEMPLATE', false)
          this.$store.commit('SET_TEMPLATE_ID', null)
        }
      },
      immediate: true,
    },

    showScratchModal(val) {
      if (!val) {
        this.$store.commit('SET_SHOW_SCRATCH_MODAL_LINDA', false)
      }
    },
  },

  methods: {
    async setPusherForDuplicationNotifications() {
      const settings = await getSettings()
      this.$store.commit('SET_SETTINGS', settings.data.data)

      Pusher.logToConsole = process.env.VUE_APP_PUSHER_DEBUG === 'true'
      const getAuthToken = () => localStorage.getItem('jilster_token')
      Vue.prototype.$pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: 'eu',
        authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        },
      })

      let that = this

      await this.systemSettingsSetup()

      let channel = this.$pusher.subscribe('private-maker.' + this.$store.getters['getLoggedInUser'].id)
      channel.bind('CreationDuplicated', function (data) {
        const payload = {
          id: data.creationDuplicate.id,
          title: data.creationDuplicate.title,
        }
        that.$store.dispatch('receiveDuplicationCreationState', payload)
        that.$store.dispatch('setDuplicationCreationState', true)
        that.$store.dispatch('newCreationDuplication', true)
        setTimeout(() => {
          that.$store.dispatch('setDuplicationCreationState', false)
          that.$store.dispatch('resetReceiveDuplicationCreationState')
        }, 20000)
      })
    },
    getCountries() {
      if (
        this.$route.name !== 'Login' &&
        this.$route.name !== 'Register' &&
        this.$route.name !== 'ForgotPassword' &&
        this.$route.name !== 'UpdatePassword' &&
        this.$route.name !== 'Invitation'
      ) {
        if (this.$store.getters['getActiveCountries'].length === 0) {
          this.$store.dispatch('setActiveCountries')
          this.$store.dispatch('getCurrentMaker')
        }
      }
    },
    bodyClick(event) {
      if (
        event.target.className !== 'customSelect activeSelect' &&
        event.target.className !== 'customOption' &&
        event.target.className !== 'icon-chevron-down'
      ) {
        this.$store.dispatch('setShowSettingsDropDown', false)
      }
    },

    startMagazine() {
      this.selectOption('default', 16)
      this.$store.commit('SET_SHOW_SCRATCH_MODAL', false)
    },

    startBook() {
      this.selectOption('default', 32)
      this.$store.commit('SET_SHOW_SCRATCH_MODAL', false)
    },

    startGlossy() {
      this.selectOption('glossy', 44)
      this.$store.commit('SET_SHOW_SCRATCH_MODAL', false)
    },

    startLinda() {
      this.selectOption('linda', 48)
      this.$store.commit('SET_SHOW_SCRATCH_MODAL', false)
    },

    startTemplate() {
      this.$router.push({ name: 'Homepage' })
      this.$store.commit('SET_SHOW_SCRATCH_MODAL', false)
    },

    async selectOption(type, pageCount) {
      const data = {
        defaultImagedirectory_id: null,
        status: 1,
        flag: 0,
        title: 'Untitled',
        pageCount: pageCount,
        showPageNumbers: 1,
        editorPreview: 0,
        shared: 0,
        lastContentChange: new Date(),
        type: type,
      }

      await this.$store.dispatch('createCreation', data)

      const id = this.$store.getters['getCreation'].id
      await this.$router.push('document/' + id)
    },
  },
  async created() {
    if (this.$store.getters['getVisitor'] === '' || this.$store.getters['getVisitor'] === undefined) {
      try {
        const result = await axios.get('https://api.db-ip.com/v2/' + process.env.VUE_APP_DBIP_KEY + '/self')
        const countryCode = result.data.countryCode
        await this.$store.dispatch('setVisitor', countryCode)
      } catch (e) {
        await this.$store.dispatch('setVisitor', 'NL')
      }
    }

    this.getCountries()

    // eslint-disable-next-line no-undef
    // const visitor = dbip.getVisitor();
    // this.$store.dispatch('setVisitor', visitor.countryCode)
    // document.body.style.backgroundColor = '#FCFCFC'

    if (
      !(
        this.$route.name === 'ForgotPassword' ||
        this.$route.name === 'Login' ||
        this.$route.name === 'Register' ||
        this.$route.name === 'Invitation' ||
        this.$route.name === 'UpdatePassword'
      )
    ) {
      await this.$store.dispatch('getCurrentMaker')
    }

    if (localStorage.getItem('jilster_token') !== null) {
      this.setPusherForDuplicationNotifications()
    }

    const settings = await getSettings()

    this.$store.commit('SET_SETTINGS', settings.data.data)

    if (this.$route.name === 'Register' && localStorage.getItem('jilster_token')) {
      this.$router.push({ name: 'Login' })
    }

    try {
      const res = await httpClient.get('/v3/template/')

      if (res && res.data && res.data.data) {
        this.$store.commit('SET_TEMPLATES', res.data.data)
      }
    } catch (err) {
      //
    }
  },

  mounted() {
    document.addEventListener('click', this.bodyClick)
  },
}
</script>

<style scoped>
.md-body >>> ul {
  list-style-type: disc;
  padding-left: 1rem;
}

.md-body >>> ul li ul {
  list-style-type: circle;
  padding-left: 2rem !important;
}
</style>

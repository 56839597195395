import {
  createCheckoutPDFOnly,
  getCheckout,
  getCheckoutPDFOnly,
  getLastorder,
  getSuccessOrder,
  updatePaymentUser,
} from './orderCheckoutEndpoints'
import { checkoutOrder } from '@/domains/order/orderCheckoutEndpoints'
// import userStore from '../user/user'

const state = {
  currentPage: 'content',
  showPopup: false,
  orderContentType: 'both',
  isLinda: false,
  isGlossy: false,
  hasNewBillingAddress: false,
  newBillingAddress: '',
  paymentMethods: [],
  calculator: [],
  printOptions: [],
  orderExtras: [],
  countries: [],
  shipmentMethods: [],
  magazine: [],
  hasCoverReady: false,
  volume: 0,
  shipment: '0',
  payment: '0',
  cannotOrder: false,
  canOrderPDFOnly: false,
  canOrderExtrasOnly: false,
  paymentUser: '',
  successOrder: {},
  orderExtraIds: '',
  print: '0',
  proof: 'false',
  isLoadingOrderItem: false,
  isLoadingOrder: false,
  showDots: false,
  selectedPosters: [],
  selectedPDFS: [],
  isEmptyOrder: false,
  isInvalidVolume: false,
}

const getters = {
  getSelectedPosters: (state) => {
    return state.selectedPosters
  },
  getSelectedPDFS: (state) => {
    return state.selectedPDFS
  },
  getPaymentUser: (state) => {
    return state.paymentUser
  },
  getCannotOrder: (state) => {
    return state.cannotOrder
  },
  getPayment: (state) => {
    return state.payment
  },
  getShipment: (state) => {
    return state.shipment
  },
  getVolume: (state) => {
    return state.volume
  },
  getCurrentPage: (state) => {
    return state.currentPage
  },
  getShowPopup: (state) => {
    return state.showPopup
  },
  getOrderContentType: (state) => {
    return state.orderContentType
  },
  getIsLinda: (state) => {
    return state.isLinda
  },
  getIsGlossy: (state) => {
    return state.isGlossy
  },
  getHasNewBillingAddress: (state) => {
    return state.hasNewBillingAddress
  },
  getNewBillingAddress: (state) => {
    return state.newBillingAddress
  },
  getCalculator: (state) => {
    return state.calculator
  },
  getPrintOptions: (state) => {
    return state.printOptions
  },
  getOrderExtras: (state) => {
    return state.orderExtras
  },
  getCountries: (state) => {
    return state.countries
  },
  getShipmentMethods: (state) => {
    return state.shipmentMethods
  },
  getMagazine: (state) => {
    return state.magazine
  },
  getPaymentMethods: (state) => {
    return state.paymentMethods
  },
  getHasCoverReady: (state) => {
    return state.hasCoverReady
  },
  getSuccessOrder: (state) => {
    return state.successOrder
  },
  getOrderExtraIds: (state) => {
    return state.orderExtraIds
  },
  getPrint: (state) => {
    return state.print
  },
  getProof: (state) => {
    return state.proof
  },
  getCanOrderPFDOnly: (state) => {
    return state.canOrderPDFOnly
  },
  getCanOrderExtrasOnly: (state) => {
    return state.canOrderExtrasOnly
  },
  getIsLoadingOrderItem: (state) => {
    return state.isLoadingOrderItem
  },
  getIsLoadingOrder: (state) => {
    return state.isLoadingOrder
  },
  getShowDots: (state) => {
    return state.showDots
  },
  getIsEmptyOrder: (state) => {
    return state.isEmptyOrder
  },
  getIsInvalidVolume: (state) => {
    return state.isInvalidVolume
  },
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  async updateUserCountryId({ commit }, settings) {
    await updatePaymentUser({
      country_id: settings.country_id && settings.country_id.toString(),
      locale: settings.locale,
    })
  },
  setSelectedPosters({ commit }, poster) {
    commit('SET_SELECTED_POSTERS', poster)
  },
  setSelectedPDFS({ commit }, pdf) {
    commit('SET_SELECTED_PDFS', pdf)
  },
  setPaymentUser({ commit }, user) {
    commit('SET_PAYMENT_USER', user)
  },
  setPayment({ commit }, payment) {
    commit('SET_PAYMENT', payment)
  },
  setShipment({ commit }, shipment) {
    commit('SET_SHIPMENT', shipment)
  },
  setVolume({ commit }, volume) {
    commit('SET_VOLUME', volume)
  },
  setHasNewBillingAddress({ commit }, bool) {
    commit('SET_HAS_NEW_BILLING_ADDRESS', bool)
  },
  setCurrentPage({ commit }, page) {
    commit('SET_CURRENTPAGE', page)
  },
  async setShowPopup({ commit }, bool) {
    await commit('SET_SHOW_POPUP', bool)
  },
  setOrderContentType({ commit }, type) {
    commit('SET_ORDER_CONTENT_TYPE', type)
  },
  setIsLinda({ commit }, bool) {
    commit('SET_IS_LINDA', bool)
  },
  setIsGlossy({ commit }, bool) {
    commit('SET_IS_GLOSSY', bool)
  },
  setNewBillingAddress({ commit }, address) {
    commit('SET_NEW_BILLING_ADDRESS', address)
  },
  setOrderExtraIds({ commit }, orderExtraIds) {
    commit('SET_ORDER_EXTRA_IDS', orderExtraIds)
  },
  setPrint({ commit }, print) {
    commit('SET_PRINT', print)
  },
  setProof({ commit }, proof) {
    commit('SET_PROOF', proof)
  },
  setCannotOrder({ commit }, status) {
    commit('SET_CANNOT_ORDER', status)
  },
  setIsEmptyOrder({ commit }, bool) {
    commit('SET_IS_EMPTY_ORDER', bool)
  },
  setIsInvalidVolume({ commit }, bool) {
    commit('SET_IS_INVALID_VOLUME', bool)
  },

  async getCheckoutPDFOnly({ commit }, order) {
    try {
      commit('SET_IS_LOADING_ORDER_ITEM', true)
      const response = await getCheckoutPDFOnly(order.id, order.orderExtraIds, order.paymentId)
      commit('SET_CALCULATOR', response.data.data.calculator)
      commit('SET_IS_LOADING_ORDER_ITEM', false)
    } catch (error) {
      //
    }
  },
  // eslint-disable-next-line no-unused-vars
  async createCheckoutPDFOnly({ commit }, order) {
    try {
      commit('SET_IS_LOADING_ORDER', true)
      const response = await createCheckoutPDFOnly(
        order.id,
        order.orderExtraIds,
        order.paymentId.toString(),
        order.firstName,
        order.lastName,
        order.gender,
        order.street,
        order.houseNumber || 0,
        order.houseNumberSuffix,
        order.postalCode,
        order.city,
        order.phone,
        order.countryId,
        order.delivery_firstName,
        order.delivery_lastName,
        order.delivery_gender,
        order.delivery_street,
        order.delivery_houseNumber || 0,
        order.delivery_houseNumberSuffix,
        order.delivery_postalCode,
        order.delivery_city,
        order.delivery_phone,
        order.delivery_countryId,
        order.company
      )

      window.location.href = response.data.data
    } catch (error) {
      //
    }
  },

  async getCheckout({ commit }, order) {
    try {
      commit('SET_IS_LOADING_ORDER_ITEM', true)
      const response = await getCheckout(
        order.id,
        order.extraOrderIds,
        order.printId,
        order.volume,
        order.deliveryId,
        order.paymentId,
        order.proof,
        order.countryId
      )
      commit('SET_CALCULATOR', response.data.data.calculator)
      commit('SET_COUNTRIES', response.data.data.countries)
      commit('SET_HASCOVERREADY', response.data.data.hasCoverReady)
      commit('SET_MAGAZINE', response.data.data.magazine)
      commit('SET_ORDER_EXTRAS', response.data.data.order_extras)
      commit('SET_PAYMENT_METHODS', response.data.data.payment_methods)
      commit('SET_PRINT_OPTIONS', response.data.data.print_options)
      commit('SET_SHIPMENT_METHODS', response.data.data.shipment_methods)
      commit('SET_IS_LOADING_ORDER_ITEM', false)
    } catch (error) {
      //
    }
  },
  // eslint-disable-next-line no-unused-vars
  async checkoutOrder({ commit }, order) {
    try {
      commit('SET_IS_LOADING_ORDER', true)
      const response = await checkoutOrder(
        order.id,
        order.orderExtraIds,
        order.printId,
        order.volume,
        order.countryId,
        order.proof,
        order.paymentId,
        order.useDeliveryAddress,
        order.firstName,
        order.lastName,
        order.gender,
        order.street,
        order.houseNumber,
        order.houseNumberSuffix,
        order.postalCode,
        order.city,
        order.phone,
        order.delivery_firstName,
        order.delivery_lastName,
        order.delivery_gender,
        order.delivery_street,
        order.delivery_houseNumber,
        order.delivery_houseNumberSuffix,
        order.delivery_postalCode,
        order.delivery_city,
        order.delivery_phone.toString(),
        order.delivery_countryId,
        order.shipmentId,
        order.company
      )

      window.location.href = response.data.data
    } catch (error) {
      //
    }
  },
  async getOrderConfirm({ commit }, identifier) {
    try {
      const response = await getSuccessOrder(identifier)
      commit('SET_SUCCESS_ORDER', response.data.data)
    } catch (error) {
      //
    }
  },
  clearCheckout({ commit }) {
    commit('CLEAR_CHECKOUT')
  },
  setCanOrderPFDOnly({ commit }, status) {
    commit('SET_CAN_ORDER_PDF_ONLY', status)
  },
  setCanOrderExtrasOnly({ commit }, status) {
    commit('SET_CAN_ORDER_EXTRAS_ONLY', status)
  },
  setIsLoadingOrderItem({ commit }, status) {
    commit('SET_IS_LOADING_ORDER_ITEM', status)
  },
  setIsLoadingOrder({ commit }, status) {
    commit('SET_IS_LOADING_ORDER', status)
  },
  setShowDots({ commit }, status) {
    commit('SET_SHOW_DOTS', status)
  },
  async getLastOrder({ commit }) {
    try {
      const result = await getLastorder()
      if (result.status === 200 && result.data.data.firstName !== null) {
        commit('SET_LAST_ORDER', result.data.data)
      }
    } catch (error) {
      //
    }
  },
}

const mutations = {
  SET_SELECTED_POSTERS(state, poster) {
    state.selectedPosters.push(poster)
  },
  SET_SELECTED_PDFS(state, pdf) {
    state.selectedPDFS.push(pdf)
  },
  SET_PAYMENT_USER(state, user) {
    state.paymentUser = user
  },
  SET_PAYMENT(state, payment) {
    state.payment = payment
  },
  SET_SHIPMENT(state, shipment) {
    state.shipment = shipment
  },
  SET_VOLUME(state, volume) {
    state.volume = volume
  },
  SET_CALCULATOR(state, calculator) {
    state.calculator = calculator
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },
  SET_HASCOVERREADY(state, hasCoverReady) {
    state.hasCoverReady = hasCoverReady
  },
  SET_MAGAZINE(state, magazine) {
    state.magazine = magazine
  },
  SET_ORDER_EXTRAS(state, orderExtras) {
    state.orderExtras = orderExtras
  },
  SET_PAYMENT_METHODS(state, paymentMethods) {
    paymentMethods.sort((a, b) => {
      return a.paymentCostsPercentage - b.paymentCostsPercentage
    })
    state.paymentMethods = paymentMethods
  },
  SET_PRINT_OPTIONS(state, printOptions) {
    state.printOptions = printOptions
  },
  SET_SHIPMENT_METHODS(state, shipmentMethods) {
    state.shipmentMethods = shipmentMethods
  },
  SET_NEW_BILLING_ADDRESS(state, address) {
    state.newBillingAddress = address
  },
  SET_HAS_NEW_BILLING_ADDRESS(state, bool) {
    state.hasNewBillingAddress = bool
  },
  SET_CURRENTPAGE(state, page) {
    state.currentPage = page
    window.scrollTo(0, 0)
  },
  SET_SHOW_POPUP(state, bool) {
    state.showPopup = bool
  },
  SET_ORDER_CONTENT_TYPE(state, type) {
    state.orderContentType = type
  },
  SET_IS_LINDA(state, bool) {
    state.isLinda = bool
  },
  SET_IS_GLOSSY(state, bool) {
    state.isGlossy = bool
  },
  SET_SUCCESS_ORDER(state, successOrder) {
    state.successOrder = successOrder
  },
  SET_ORDER_EXTRA_IDS(state, orderExtraIds) {
    state.orderExtraIds = orderExtraIds
  },
  SET_PRINT(state, print) {
    state.print = print
  },
  SET_PROOF(state, proof) {
    state.proof = proof
  },
  SET_CANNOT_ORDER(state, status) {
    state.cannotOrder = status
  },
  CLEAR_CHECKOUT(state) {
    state.currentPage = 'content'
    state.showPopup = false
    state.hasNewBillingAddress = false
    state.newBillingAddress = ''
    state.paymentMethods = []
    state.calculator = []
    state.printOptions = []
    state.orderExtras = []
    state.countries = []
    state.shipmentMethods = []
    state.magazine = []
    state.hasCoverReady = false
    state.volume = 0
    state.shipment = '0'
    state.payment = '0'
    state.cannotOrder = false
    state.paymentUser = ''
    state.successOrder = {}
    state.orderExtraIds = ''
    state.print = '0'
    state.proof = 'false'
    state.canOrderExtrasOnly = false
    state.canOrderPDFOnly = false
  },
  SET_CAN_ORDER_PDF_ONLY(state, status) {
    state.canOrderPDFOnly = status
  },
  SET_CAN_ORDER_EXTRAS_ONLY(state, status) {
    state.canOrderExtrasOnly = status
  },
  SET_IS_LOADING_ORDER_ITEM(state, status) {
    state.isLoadingOrderItem = status
  },
  SET_IS_LOADING_ORDER(state, status) {
    state.isLoadingOrder = status
  },
  SET_SHOW_DOTS(state, status) {
    state.showDots = status
  },
  SET_LAST_ORDER(state, lastOrder) {
    const phone = lastOrder.mobileNumber && lastOrder.mobileNumber.toString()
    let area
    let phoneNumber
    if (phone.includes(' ')) {
      area = phone.split(' ')[0]
      phoneNumber = phone.split(' ')[1]
    } else {
      area = ''
      phoneNumber = phone
    }

    state.paymentUser = {
      city: lastOrder.city,
      countryId: lastOrder.country_id,
      firstName: lastOrder.firstName,
      houseNumber: lastOrder.houseNumber,
      houseNumberSuffix: lastOrder.houseNumberSuffix,
      lastName: lastOrder.lastName,
      area: parseInt(area),
      phonenumber: parseInt(phoneNumber),
      postalCode: lastOrder.postalCode,
      street: lastOrder.street,
    }
  },
  SET_IS_EMPTY_ORDER(state, bool) {
    state.isEmptyOrder = bool
  },
  SET_IS_INVALID_VOLUME(state, bool) {
    state.isInvalidVolume = bool
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
